/* Modal container */
.modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
}

.modal-hide {
  display: none;
}

/* Modal content */
.modal-content {
  background-color: #fefefe;
  margin: 10% auto; /* Center the modal vertically and horizontally */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
}

.modal-content h3 {
  font-size: 2rem;
  line-height: 50px;
  margin-bottom: 1.5rem;
  padding: 0px;
  border-bottom: 5px solid white;
  display: flex;
  align-items: center;
  line-height: normal;
  white-space: nowrap;
}

.modal-content h3 svg {
  margin-right: 5px;
  width: 28px;
  height: 28px;
  position: relative;
  bottom: 3px;
}

.modal-content h4 {
  font-size: 2rem;
  line-height: 30px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 5px solid #f88;
  display: flex;
}

.modal-content h4 a {
  color: #f08519;
  font-size: 1rem;
}

.close-button {
  color: #f08519;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.close-button:hover {
  transform: rotate(180deg);
}

/* Close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-list > li {
  font-size: 1.6rem;
  line-height: 30px;
  margin-bottom: 3rem;
}

.modal-cta {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.location-info p {
  line-height: 30px;
  font-size: 0.95rem;
}

.location-info h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location-info h4 a {
  color: #f08519;
  font-size: 1rem;
}

.location-info h4 span {
  color: black;
  font-size: 1.5rem;
}

.location-info {
  font-family: "Roboto", sans-serif;
}

.location-info a {
  color: #f08519;
  display: inline-flex;
  align-items: center;
}

.location-info a svg {
  height: 13px;
}

/* Modal fade in animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply fade in animation to modal */
.modal {
  animation: modalFadeIn 0.3s ease-in-out;
}

@media screen and (min-width: 900px) {
  .modal-content h3 {
    font-size: 2.8rem;
  }

  .location-info h4 span {
    font-size: 2rem;
  }

  .modal-content h3 svg {
    width: 36px;
    height: 36px;
  }

  .location-info h4 a {
    font-size: 1.5rem;
  }

  .location-info a svg {
    height: 20px;
  }

  .location-info p {
    font-size: 1.2rem;
  }
}
