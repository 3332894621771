#price-header,
#admission,
#party {
  padding: 6rem 1rem;
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/background/rulesbg.webp") no-repeat center center/cover;
  background-color: #1e1e1e;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* #price-header::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: linear-gradient(to top, black, transparent);
    z-index: 100;
} */
.notice {
  border-radius: 5px;
  border: black 10px solid;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
}

#admission {
  background: url("../../assets/prices/GA.webp") no-repeat center center/cover;
}

#party {
  background: url("../../assets/prices/newParty.webp") no-repeat center center/cover;
}

.price-content {
  max-width: 930px;
  margin-top: 0.5rem;
}

.price-content img {
  width: 100%;
}

.link-blue {
  color: var(--primary-color);
}

.link-blue:hover {
  border-bottom: 5px solid white;
  transition: 0.2s ease-in-out;
}

#price-header .price-desc {
  max-width: 800px;
  margin-bottom: 50px;
  text-align: center;
  padding: 1rem;
}

#price-header .price-desc p {
  font-size: 1.5rem;
  color: #fff;
  padding: 2rem;
  height: 300px;
}

#price-header .price-desc h2 {
  padding: 5px;
}

.info {
  font-size: 1.8rem;
}

.price p {
  font-size: 1.5rem;
  padding: 5px;
  color: white;
  /* background-color: rgba(0, 0, 0, .8); */
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
  text-align: center;
}

.buyout {
  font-size: 2.2rem;
  color: white;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}

#admission h3 {
  font-size: 2.2rem;
  color: white;
  text-shadow: 0 0 1.5px black, 0 0 1.5px black, 0 0 1.5px black, 0 0 1.5px black;
  margin-bottom: 20px;
  text-align: center;
}

#price-header .container {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

#price-header .content {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 290px;
  z-index: 10;
}

.arrow-down {
  animation: upDown 1s ease infinite;
  font-size: 3.2rem;
  color: white;
}

.media-arrow .arrow-down {
  display: none;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

#price-header button {
  text-transform: uppercase;
  width: 200px;
  margin-bottom: 1rem;
  border: 4px solid dodgerblue;
}

#price-header button:hover {
  border: 4px solid var(--primary-color);
}

#price-header h1,
#admission h1,
#party h1 {
  text-align: center;
  overflow: hidden;
}

#price-header h2 {
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
  font-size: 3.5rem;
  margin-bottom: 30px;
}

#admission .container,
#party .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

#admission .content,
#party .content {
  width: auto;
  max-width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

#admission .note {
  font-size: 1rem;
}

/* ----------------------------- TABLE ----------------------------- */
#admission table {
  background: radial-gradient(circle, rgb(245, 159, 244) 25%, rgb(125, 185, 253) 100%);
  background-size: 500%;
  animation: gradient 10s ease infinite;
  transition: 0.2s ease;
}

table {
  border-collapse: collapse;
  margin: 10px 0;
  background: radial-gradient(circle, rgb(245, 159, 244) 25%, rgb(125, 185, 253) 100%);
  background-size: 500%;
  animation: gradient 10s ease infinite;
  transition: 0.2s ease;
  border: 5px solid black;
}

th,
td {
  padding: 12px;
  text-align: center;
  font-size: 1.8rem;
}

.td1 {
  /* text-align: left !important; */
  border-right: 3px solid black;
}

tr {
  border-bottom: 3px solid black;
}

tr:hover,
tr:hover p {
  color: white !important;
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black !important;
}

table p {
  color: black !important;
  text-shadow: none !important;
  padding: 0px !important;
  background-color: transparent !important;
}

.details {
  border-radius: 5px;
  margin-top: 50px;
  padding: 10px;
  border: black 10px solid;
  background-color: rgba(0, 0, 0, 0.8);
}

.miniDetails {
  font-size: 1.2rem !important;
  padding: 5px !important;
  margin-top: 0px !important;
  border: 3px solid white;
}

@media screen and (max-width: 840px) {
  #price-header .price-desc p {
    font-size: 1.4rem;
    color: #fff;
    padding: 1rem;
    height: auto;
  }
}

@media screen and (max-width: 752px) {
  #admission h1,
  #party h1 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 660px) {
  #price-header h2 {
    font-size: 3rem;
  }

  #price-header button {
    padding: 0px;
    width: 147px;
    font-size: 1.2rem;
  }

  th,
  td {
    padding: 5px 0px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 580px) {
  #price-header h2 {
    font-size: 2.5rem;
  }

  #price-header button {
    padding: 0px;
    width: 147px;
    font-size: 1.19rem;
  }

  #price-header .content {
    gap: 200px;
  }
}

@media screen and (max-width: 480px) {
  .arrow-down {
    display: none;
  }

  .media-arrow .arrow-down {
    display: inline-block;
  }

  .media-arrow {
    display: flex;
    justify-content: center;
    gap: 115px;
  }

  #price-header {
    height: auto;
  }

  #price-header .content {
    padding: 0px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* width: 147px; */
    font-size: 1.19rem;
  }

  #price-header .price-desc p {
    font-size: 1.2rem;
    padding: 5px;
  }

  .price p {
    font-size: 1.3rem;
    padding: 10px;
  }

  .miniDetails {
    font-size: 1.05rem !important;
  }

  #party h1 {
    font-size: 3rem !important;
  }

  th,
  td {
    padding: 5px 4px;
    font-size: 1.3rem;
  }
}

.party-room-btn-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}

.party-room-btn-content button {
  width: 200px;
  margin-bottom: 1rem;
  border: 4px solid dodgerblue;
}

.party-room-btn-content button.active {
  background-color: dodgerblue;
  border: 1px solid dodgerblue;
  letter-spacing: 1px;
  transform: translateY(-3px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 1);
}

@media screen and (min-width: 480px) {
  .party-room-btn-content {
    flex-direction: row;
  }
}
