.contact {
  width: 100%;
  min-height: 100vh;
  padding: 6rem 1rem;
  background: url("../../assets/background/kids-wall.webp") no-repeat center center/cover;
  background-color: #1e1e1e;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact .container {
  display: flex;
  width: 100%;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact .pdf-download-container a {
  font-size: 1.5rem;
  color: #f08519;
}

.contact .pdf-download-container svg {
  position: relative;
  top: 2.5px;
}

.contact .file-attach {
  color: #f08519;
  border: none;
  padding: 0;
  margin-top: -15px;
  cursor: pointer;
}

.contact .container .main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  height: 900px;
  padding: 2rem;
  gap: 30px;
}

.contact .content {
  min-height: 100%;
  height: auto;
  width: 50%;
  display: flex;
  border: 20px solid black;
}

.phone-number {
  letter-spacing: 1.5px;
}

.contact h1 {
  overflow: hidden;
  text-shadow: 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black;
}

.contact h3 {
  font-size: 1.6rem;
  color: #f8f8f8;
  overflow: hidden;
  text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
  padding-bottom: 5px;
  text-align: center;
}

.content-info h3 {
  font-family: var(--font-default);
}

.contact h2 {
  font-size: 3.5rem;
  text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
  border-bottom: 6px solid white;
  display: block;
  margin-bottom: 10px;
}

.contact .content-form .success {
  border: none;
  display: grid;
  justify-content: center;
  align-items: center;
}

.contact .content-form form {
  width: 100%;
}

.content-info {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* gap: 40px; */
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.content-form {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2rem;
}

.content-form label {
  font-size: 1.5rem;
  color: white;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}

.content-form form {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-form input,
.content-form textarea {
  border-radius: 5px;
  display: block;
  width: 100%;
  font-size: 1.2rem;
  padding: 8px;
  font-family: var(--font-default);

  outline: none;
  border: 3px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
  transition: all 0.4s ease;
}

.content-form input:focus,
.content-form textarea:focus {
  border-color: dodgerblue;
}

.content-form select {
  border-radius: 5px;

  padding: 5px;
  outline: none;
  display: block;
  width: 100%;
  padding: 10px 5px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: #fff;
  font-family: var(--font-default);
}

.content-form option {
  width: 100%;
  font-size: 1.2rem;
  font-family: var(--font-default);
}

.content-form textarea {
  max-width: 100%;
  min-width: 100%;
  height: 130px;
}

.content-form span {
  color: red;
  text-shadow: none;
}

.content-form p {
  color: white;
}

.googlemap {
  width: 100%;
  height: 40vh;
  padding: 0 2rem;
}

.google-map {
  width: 100%;
  height: 100%;
  border: 20px solid black;
  overflow: hidden;
}

.pin-name {
  font-size: 1.3rem;
  color: white;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}

.pin-text {
  font-size: 1.3em;
  font-weight: 700;
  position: absolute;
  top: -30px;
  left: 10px;
  width: 150px;
  text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white;
}

@media screen and (max-width: 1024px) {
  .content-form {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1rem;
  }

  .content-form label {
    font-size: 1.3rem;
  }

  .content-form input,
  .content-form textarea {
    font-size: 1.2rem;
    padding: 6px;
  }

  .content-form select {
    padding: 5px;
    padding: 6px 5px;
    font-size: 1.1rem;
  }

  .content-form option {
    font-size: 1.1rem;
  }

  .content-form textarea {
    max-width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 940px) {
  .content-form label {
    margin-top: 10px;
  }

  .contact .container .main-content {
    display: flex;
    width: 100%;
    height: auto;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .contact .content {
    width: 100%;
  }

  .contact h2 {
    font-size: 3rem;
    margin-top: 30px;
  }

  .contact h3 {
    font-size: 1.5rem;
    padding-bottom: 10px;
  }

  .contact .file-attach {
    margin-top: 0;
  }
}

@media screen and (max-width: 620px) {
}

@media screen and (max-width: 470px) {
  .contact .container .main-content {
    padding: 0rem;
  }

  .contact {
    padding: 6rem 0rem;
  }

  .googlemap {
    padding: 0;
    margin-top: 30px;
  }
}

@media screen and (max-width: 375px) {
  .content-form {
    padding: 1rem 0.5rem;
  }

  .contact .content {
    border: 0px solid black;
  }

  .contact .container .main-content {
    width: 100%;
    border: 0px solid black;
  }

  .google-map {
    border: 0px;
  }
}
