/* WAVES */

.w {
  position: absolute;
  top: 95%;
  left: -25vw;
  right: 0px;
  background: url(https://i.koya.io/whitewave2.svg);
  background-position: center;
}

.wave {
  height: 200px;
  transform: translateY(-40px);
}

.wave2 {
  height: 240px;
  transform: translateY(-80px);
  background-size: 960px 240px;
  opacity: 0.5;
  left: -80vw;
  right: -5vw;
}

.wave3 {
  height: 220px;
  transform: translateY(-120px);
  background-size: 880px 220px;
  opacity: 0.33;
  left: 0vw;
  right: -20vw;
}

.wave4 {
  height: 280px;
  transform: translateY(-160px);
  background-size: 1120px 280px;
  opacity: 0.1;
  left: 0vw;
  right: -40vw;
}

.rules-video {
  position: relative;
}

.rules-video video {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 10px black, 0 0 10px black, 0 0 10px black, 0 0 10px black;
}

.rules-video .play-icon {
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.rules-video .play-icon svg {
  width: 60px;
  height: 60px;
  transition: 0.2s;
}

.rules-video .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  cursor: pointer;
}

.rules-video .video-overlay:hover .play-icon svg {
  transform: scale(1.1);
}

.rules-video .video-overlay h3 {
  color: white;
  font-size: 1.5rem;
  margin-top: 15px;
  margin-left: 15px;
}

.rules {
  width: 100%;
  min-height: 100vh;
  padding: 6rem 1rem;

  overflow: hidden;
  position: relative;
  background: radial-gradient(circle, #24a4ea, #379deb 25%, #b76bf0);

  background-size: 500%;
  animation: gradient 15s ease infinite;
  transition: 0.2s ease;

  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.rules .rules-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.rules .content {
  width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 10;
  margin: 1rem;
  gap: 20px;
}

.rules .rules-desc .desc {
  color: white;
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}

.rules .collapsible .collapse-header {
  box-sizing: content-box;
  border-bottom: 2px solid black;
}

.rules .collapsible .collapse-content {
  text-align: left;
  background-color: white;
}

.rules .collapsible {
  box-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
}

@media screen and (max-width: 840px) {
  .rules .content {
    width: auto;
  }
}

@media screen and (min-width: 900px) {
  .rules-video .video-overlay h3 {
    font-size: 3rem;
  }
}

.hide {
  display: none;
}
