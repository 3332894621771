.calendar {
  padding: 6rem 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("../../assets/background/calendar.webp") no-repeat center center/cover;
  display: flex;
  /* justify-content: start; */
  align-items: center;
  flex-direction: column;
  background-color: #1e1e1e;
}

.calendar-container {
  width: 100vw;
  height: 1010px;
  min-height: 100vh;
  margin: 0px;
  padding: 0px 10vh;
  /* min-height: 100vh; */
}

.styled-calendar-container {
  width: 100%;
  height: 100%;
  border: none;

  /* min-height: 100vh; */
}
@media screen and (max-width: 1300px) {
  .calendar-container {
    padding: 20px 5vh;
  }
}

@media screen and (max-width: 950px) {
  .calendar-container {
    padding: 20px 0px;
  }
}
@media screen and (max-width: 575px) {
  .calendar-container {
    padding: 20px 0px;
    height: 50vh;
  }
}
