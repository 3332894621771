/* CSS VARIABLES */
:root {
  --primary-color: #5bc6f6;
  --primary-dark: #00306e;
  --primary-button: #47dcff;
  --font-default: "Roboto", sans-serif;
  --font-kids: "Bubblegum Sans", "BalooBhaina-Regular";
}

@font-face {
  font-family: "BalooBhaina-Regular";
  src: url(../public/fonts/BalooBhaina-Regular.woff) format("woff");
}

body {
  overflow-x: hidden;
}

.collapsible .collapse-content {
  padding: 10px;
  background-color: rgb(240, 240, 240);
  font-family: var(--font-default);
}

.collapsible .collapse-header {
  transition: 0.2s ease-in-out;
  background-color: rgb(200, 200, 200);
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--font-default);

  /* border-bottom: 2px solid rgb(220, 220, 220); */
}

.collapsible .collapse-header .collapse-icon {
  margin-right: 10px;
}

span {
  color: var(--primary-color);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #fff;
}

p {
  font-size: 1.2rem;
  line-height: 1.4rem;
  overflow: hidden;
}

.title {
  font-size: 6rem;
  color: #f8f8f8;
  padding: 5px 10px;
  overflow: hidden;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
  transition: all 0.2s ease-in-out;
  cursor: default;
}

.title:hover {
  text-shadow: none !important;
  background-image: linear-gradient(
    90deg,
    rgba(255, 212, 1, 1) 15%,
    rgba(255, 145, 0, 1) 39%,
    rgba(255, 118, 210, 1) 61%,
    rgba(91, 198, 246, 1) 85%
  );
  -webkit-background-clip: text;
  color: transparent;
  transform: scale(1.1);
  transition: 0.2s all;
}

/* SWITCH */

.toggle p {
  padding: 5px;
  z-index: 10;
  color: white;
  text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black !important;
}

.toggle {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  margin: -20px 0;
}

.toggle .toggle-group {
  margin: 1rem 0;
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  box-shadow: 0 0 0 3px white;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 5px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2px;
}

input:checked + .blue {
  background-color: var(--primary-color);
}

input:checked + .yellow {
  background-color: #fed105;
}

input:checked + .pink {
  background-color: #fe9db0;
}

input:checked + .green {
  background-color: #8eba24;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

h2 {
  font-size: 4rem;
  color: #f8f8f8;
  overflow: hidden;
}

h3 {
  padding: 0 5px;
}

/* LINK EFFECTS */
.link-hover {
  position: relative;
}

.link-hover:after {
  content: "";
  position: absolute;
  bottom: -3px;
  height: 4px;
  width: 0;
  right: 0;
  transition: all 300ms ease;
  box-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
}

.active.link-hover:after {
  width: 100%;
}

.link__hover-color:after {
  background: white;
}

.link-hover:hover:after {
  left: 0;
  width: 100%;
}

/* -------------------------------------------- */

.container {
  max-width: 1240px;
  margin: auto;
}

button {
  font-family: var(--font-kids);
  padding: 0.7rem;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #f8f8f8;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.2s linear;
  height: 50px;
  cursor: pointer;
}

button:hover {
  background-color: dodgerblue;
  border: 1px solid dodgerblue;
  letter-spacing: 1px;
  transform: translateY(-3px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 1);
}

button:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
}

/* @media screen and (max-width: 940px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }
} */

/* Default index.css styling */

body {
  margin: 0;
  font-family: "Bubblegum Sans", "BalooBhaina-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
