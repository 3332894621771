.home {
  padding: 6rem 1rem;
  width: 100vw;
  overflow: hidden;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.home .parallax-video {
  overflow: hidden;
}

.home .bg-video {
  position: fixed;
  top: 0;
  left: -100%;
  height: auto;
  object-fit: cover;
  z-index: -2;
  vertical-align: baseline;
}

@media (min-width: 600px) and (orientation: landscape) {
  .home .bg-video {
    width: 100vw;
    height: 100vh;
    left: 0;
  }
}

@media screen and (min-width: 1400px) {
  .home .bg-video {
    width: 100vw;
    height: 100vh;
    left: 0;
  }
}

@media screen and (min-height: 932px) {
  .home .bg-video {
    width: 100vw;
    left: 0;
    height: 100vh;
  }
}

.home .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  text-align: center;
  padding-bottom: 2rem;
  z-index: 10;
}

.home h1 {
  cursor: pointer;
  font-size: 8rem;
  padding-top: 3.2rem;
  color: #f8f8f8;
  overflow: hidden;
  letter-spacing: 2px;
  font-family: "Chela One", "BalooBhaina-Regular";
  text-shadow: 0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white !important;
  z-index: 10;
}

.home h1:hover {
  text-shadow: 0 0 12px white, 0 0 12px white, 0 0 12px white, 0 0 12px white !important;
}

.home h1:active {
  text-shadow: 0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white !important;
}

.bouncing-text {
  display: flex;
}

.bouncing-text span {
  text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white !important;
  font-size: 3.2rem;
  padding-left: 3px;
  z-index: 10;
  display: flex;
  height: 65px;
  align-items: center;
}

.scroll-down {
  position: absolute;
  bottom: 30%;
  right: 10px;
}

.scroll-down svg {
  cursor: pointer;
  width: 50px;
  height: 50px;
  animation: bounce 1.7s ease infinite;
  -webkit-animation: bounce 1.7s ease infinite;
}

.scroll-down svg path {
  fill: var(--primary-color);
}

@media screen and (min-width: 688px) {
  .scroll-down {
    right: initial;
    left: 60px;
    bottom: 10%;
  }
}

.one {
  animation: bounce 1.8s ease infinite;
  -webkit-animation: bounce 1.8s ease infinite;
}

.two {
  animation: bounce 1.8s ease infinite 0.1s;
  -webkit-animation: bounce 1.8s ease infinite 0.1s;
}

.three {
  animation: bounce 1.8s ease infinite 0.2s;
  -webkit-animation: bounce 1.8s ease infinite 0.2s;
}

.four {
  animation: bounce 1.8s ease infinite 0.3s;
  -webkit-animation: bounce 1.8s ease infinite 0.3s;
}

.five {
  animation: bounce 1.8s ease infinite 0.4s;
  -webkit-animation: bounce 1.8s ease infinite 0.4s;
}

.six {
  animation: bounce 1.8s ease infinite 0.5s;
  -webkit-animation: bounce 1.8s ease infinite 0.5s;
}

.seven {
  animation: bounce 1.8s ease infinite 0.6s;
  -webkit-animation: bounce 1.8s ease infinite 0.6s;
}

.eight {
  animation: bounce 1.8s ease infinite 0.7s;
  -webkit-animation: bounce 1.8s ease infinite 0.7s;
}

.nine {
  animation: bounce 1.8s ease infinite 0.8s;
  -webkit-animation: bounce 1.8s ease infinite 0.8s;
}

.ten {
  animation: bounce 1.8s ease infinite 0.9s;
  -webkit-animation: bounce 1.8s ease infinite 0.9s;
}

.eleven {
  animation: bounce 1.8s ease infinite 1s;
  -webkit-animation: bounce 1.8s ease infinite 1s;
}

.twelve {
  animation: bounce 1.8s ease infinite 1.1s;
  -webkit-animation: bounce 1.8s ease infinite 1.1s;
}

.thirteen {
  animation: bounce 1.8s ease infinite 1.2s;
  -webkit-animation: bounce 1.8s ease infinite 1.2s;
}

.fourteen {
  animation: bounce 1.8s ease infinite 1.3s;
  -webkit-animation: bounce 1.8s ease infinite 1.3s;
}

.fifteen {
  animation: bounce 1.8s ease infinite 1.4s;
  -webkit-animation: bounce 1.8s ease infinite 1.4s;
}

.sixteen {
  animation: bounce 1.8s ease infinite 1.5s;
  -webkit-animation: bounce 1.8s ease infinite 1.5s;
}

.seventeen {
  animation: bounce 1.8s ease infinite 1.6s;
  -webkit-animation: bounce 1.8s ease infinite 1.6s;
}

.eighteen {
  animation: bounce 1.8s ease infinite 1.7s;
  -webkit-animation: bounce 1.8s ease infinite 1.7s;
}

.nineteen {
  animation: bounce 1.8s ease infinite 1.8s;
  -webkit-animation: bounce 1.8s ease infinite 1.8s;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.85, 1.1) translateY(-8px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  58% {
    transform: scale(1, 1) translateY(0);
  }

  65% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-8px);
  }

  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }

  58% {
    transform: scale(1, 1) translateY(0);
  }

  65% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

/* --------------------------------------------------------------- */

.space-right-sub {
  margin-right: 1rem;
}

@media screen and (min-width: 900px) {
  .space-right {
    margin-right: 1.5rem;
  }

  .hide-desktop {
    display: none;
  }

  .bouncing-text span {
    font-size: 4.5rem;
    padding-top: 1rem;
    height: 85px;
  }
}

.home button {
  font-size: 1.7rem;
  cursor: pointer;
  margin-top: 2rem;
}

.home .btn {
  z-index: 10;
}

.home .blue {
  color: var(--primary-color);
}

.home .orange {
  color: #fa9335;
}

.home .green {
  color: #8eba24;
}

.home .yellow {
  color: #fed105;
}

.home .pink {
  color: #fb76d1;
}

.home .teal {
  color: #0cb1ab;
}

.home .lightpink {
  color: #fe9db0;
}

.home .hotpink {
  color: #fe407e;
}

.home .desktop-character {
  display: none;
}

@media screen and (min-width: 900px) {
  .home .image .desktop-character {
    display: block;
    width: 500px;
  }

  .home .mobile-character {
    display: none;
  }
}
@media screen and (max-width: 900px) and (min-height: 900px) {
  .home .image img {
    transform: translate(-50%, 0%) !important;
  }
}

@media screen and (min-width: 900px) and (min-height: 900px) {
  .home .image img {
    width: 800px !important;
  }
}

.home .image img {
  height: auto;
  width: 600px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 25%);
  overflow: hidden;
  z-index: 1;
}

@media screen and (max-height: 670px) and (max-width: 900px) {
  .home .image img {
    width: 300px !important;
  }
}

@media screen and (min-width: 2000px) {
  .home h1 {
    font-size: 10rem;
  }

  .home .content {
    height: 1000px;
  }

  .home button {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 1100px) {
  .title:hover {
    transform: scale(1);
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 1000px) {
  .home h1 {
    font-size: 7rem;
  }

  .home .content {
    height: 650px;
  }

  .home button {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 880px) {
  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }

    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }

    30% {
      transform: scale(0.9, 1.1) translateY(-5px);
    }

    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }

    58% {
      transform: scale(1, 1) translateY(-1px);
    }

    65% {
      transform: scale(1, 1) translateY(0);
    }

    100% {
      transform: scale(1, 1) translateY(0);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }

    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }

    30% {
      transform: scale(0.9, 1.1) translateY(-5px);
    }

    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }

    58% {
      transform: scale(1, 1) translateY(-1px);
    }

    65% {
      transform: scale(1, 1) translateY(0);
    }

    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
}

@media screen and (max-width: 550px) {
  .home .image img {
    width: 450px !important;
    transform: translate(-50%, 0);
  }

  .home h1 {
    font-size: 4.5rem !important;
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white !important;
    padding-top: 1.5rem;
  }

  .home h1:hover {
    text-shadow: 0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white !important;
  }

  .home button {
    margin-top: 1rem;
  }

  .bouncing-text span {
    font-size: 2.25rem;
    height: 48px;
  }
}

/* iPhone Standard Size */
@media screen and (max-width: 400px) {
  .home .image img {
    transform: translate(-50%, 0);
    width: 400px !important;
  }
  .bouncing-text span {
    font-size: 2rem;
    height: 45px;
  }
}

/* iPhone SE */
@media screen and (max-width: 375px) and (max-height: 568px) {
  .home .image img {
    width: 340px !important;
  }

  .home h1 {
    font-size: 3.5rem !important;
  }
}

.ocean {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f3cc6f;
}

.wave {
  background: url(../../assets/background/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -158px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1600px;
  }
}

.location-cta {
  position: fixed;
  z-index: 100;
  right: 10px;
  bottom: 8px;
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  border: 2px solid white;
  font-size: 1.1rem;
  background: radial-gradient(circle, #ffd401, #ff9100, #ff76d2, #5bc6f6 90%);
  background-size: 500%;
  animation: gradient 3s ease infinite;
  transition: 0.2s ease;
}

.location-cta:hover {
  box-shadow: none;
  border: 2px solid white;
  transform: scale(1.1);
  letter-spacing: initial;
}

.location-cta:hover svg {
  animation: locationIconBounce 0.3s ease infinite alternate;
}

.location-cta span {
  color: white;
  margin-bottom: 18px;
}

.location-cta svg {
  color: #f3cc6f;
  position: absolute;
  bottom: 6px;
  height: 25px;
}

@media screen and (min-width: 900px) {
  .location-cta {
    height: 7rem;
    width: 7rem;
    font-size: 1.3rem;
    bottom: 50%;
  }
}

@keyframes locationIconBounce {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@media screen and (max-width: 475px) {
  .title {
    font-size: 4rem;
  }
}
